import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com'

const RapidCoins = () => {
  const [coinPrice, setCoinPrice] = useState('');
  const navigate = useNavigate()
//   const [lockPeriod, setLockPeriod] = useState(180);

let token = localStorage.getItem('authToken')
  let Authorization = `Bearer ${token}`

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/api/investment/tokenPrice`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': Authorization
        },
        body: JSON.stringify({ price : coinPrice }),
      });

      if (response.ok) {
        alert('Token price updated successfully');
      } else {
        const errorData = await response.json();
        if(errorData.error == "Request is not authorized"){
            localStorage.removeItem('authToken')
            navigate('/')
          }
        if(errorData.error == 'Token price can only be updated before 11 AM '){
          alert('Token price can only be updated before 11 AM.')
        }
        console.error('Error updating the token price and lock period:', errorData);
      }
    } catch (error) {
      console.error('Error updating the token price and lock period:', error);
    }
  };

  useEffect(() => {
    const fetchTodayTokenPrice = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/investment/todaysTokenPrice`);
        setCoinPrice(response.data.latestPrice.price);
      } catch (error) {
        console.error('Error fetching the previous token price:', error);
      }
    };

    fetchTodayTokenPrice();
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="coinPrice" className="block text-gray-700 mb-3">
            Coin Price
          </label>
          <input
            type="number"
            id="coinPrice"
            value={coinPrice}
            onChange={(e) => setCoinPrice(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lockPeriod" className="block text-gray-700 mb-3">
            Lock Period (days)
          </label>
          <input
            type="number"
            id="lockPeriod"
            value={180}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-blue-600"
        >
          Save 
        </button>
      </form>
    </div>
  );
};

export default RapidCoins;
