import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = 'https://api.rapidxpro.com';

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [onlineUsers, setOnlineUsers] = useState(3);
  const [totalWallet, setTotalWallet] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalRapidCoins, setTotalRapidCoins] = useState(0);
  const [totalWithdrawnCoins, setTotalWithdrawnCoins] = useState(0);

  const navigate = useNavigate();

  let token = localStorage.getItem('authToken');
  let Authorization = `Bearer ${token}`;

  useEffect(() => {
    // Fetch total users and online users
    fetch(`${baseUrl}/api/superAdmin/totalUsers`, {
      headers: {
        'Authorization': Authorization
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.error === "Request is not authorized") {
          localStorage.removeItem('authToken');
          navigate('/');
        } else {
          setTotalUsers(data.userCount);
          // setOnlineUsers(data.userCount);
        }
      })
      .catch(error => console.error('Error fetching total users:', error));

    // Fetch total wallet amount added today
    fetch(`${baseUrl}/api/superAdmin/totalCoinsAddedToday`, {
      headers: {
        'Authorization': Authorization
      }
    })
      .then(response => response.json())
      .then(data => {
        setTotalWallet(data.totalAamountAddedToday || 0);
      })
      .catch(error => console.error('Error fetching total wallet:', error));

    // Fetch total amount withdrawn
    fetch(`${baseUrl}/api/superAdmin/totalWithdrawl`, {
      headers: {
        'Authorization': Authorization
      }
    })
      .then(response => response.json())
      .then(data => {
        
        setTotalWithdraw(data.totalWithdrawnToday);
        console.log(data, totalWithdraw)
      })
      .catch(error => console.error('Error fetching total withdraw:', error));

      fetch(`${baseUrl}/api/superAdmin/todaysRapidcoins`, {
        headers: {
          'Authorization': Authorization
        }
      })
        .then(response => response.json())
        .then(data => {
          setTotalRapidCoins((data.totalRapidCoinsToday).toFixed(2) || 0);
        })
        .catch(error => console.error('Error fetching total withdraw:', error));

        fetch(`${baseUrl}/api/superAdmin/todaysWithdrawnCoins`, {
          headers: {
            'Authorization': Authorization
          }
        })
          .then(response => response.json())
          .then(data => {
            setTotalWithdrawnCoins((data.totalRapidCoinsToday).toFixed(2) || 0);
          })
          .catch(error => console.error('Error fetching total withdraw:', error));
  }, []);

  const fetchOnlineUsers = async () => {
    try {
      const response = await fetch('http://localhost:4002/api/superAdmin/online-users');
      const data = await response.json();
      // setOnlineUsers(data.onlineUsers);
      setOnlineUsers(4);
    } catch (error) {
      console.error('Error fetching online users:', error);
    }
  };

  useEffect(() => {
    // Fetch online users on component mount
    fetchOnlineUsers();

    // Fetch online users periodically (e.g., every 10 seconds)
    const intervalId = setInterval(fetchOnlineUsers, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="grid gap-6 md:grid-cols-2">
      <div className="p-4 bg-white shadow rounded-lg flex items-center">
        <div className="bg-blue-500 p-3 rounded-full">
        <svg
          className="w-6 h-6 text-white"  
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >


          {/* Users Icon */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 14c-4 0-7-3-7-7s3-7 7-7 7 3 7 7-3 7-7 7zM5 21v-2c0-2.21 4-4 7-4s7 1.79 7 4v2"  
          />
        </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Total Users</h4>
          <p className="mt-1 text-2xl">{totalUsers}</p>
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg flex items-center">
        <div className="bg-green-500 p-3 rounded-full">
        <svg
          className="w-6 h-6 text-white"  
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
    

          {/* Users Icon */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 14c-4 0-7-3-7-7s3-7 7-7 7 3 7 7-3 7-7 7zM5 21v-2c0-2.21 4-4 7-4s7 1.79 7 4v2" 
          />
        </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Online Users</h4>
          <p className="mt-1 text-2xl">{onlineUsers}</p>
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg flex items-center">
      <div className="bg-green-500 p-3 rounded-full">
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Total Amount In</h4>
          <p className="mt-1 text-2xl">{totalWallet}</p>
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg flex items-center">
        <div className="bg-red-500 p-3 rounded-full">
        <svg
          className="w-6 h-6 text-white" 
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
         
          
          
          {/* Minus icon */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 12h14" 
          />
        </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Total Amount Out</h4>
          <p className="mt-1 text-2xl">{totalWithdraw}</p>
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg flex items-center">
      <div className="bg-green-500 p-3 rounded-full">
          <svg
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Todays Rapid Coins Investment</h4>
          <p className="mt-1 text-2xl">{totalRapidCoins}</p>
        </div>
      </div>

      <div className="p-4 bg-white shadow rounded-lg flex items-center">
        <div className="bg-red-500 p-3 rounded-full">
        <svg
          className="w-6 h-6 text-white" 
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
         
          
          
          {/* Minus icon */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 12h14" 
          />
        </svg>
        </div>
        <div className="ml-4">
          <h4 className="text-lg font-semibold">Total Withdrawn Coins</h4>
          <p className="mt-1 text-2xl">{totalWithdrawnCoins}</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
