import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const baseUrl = 'https://api.rapidxpro.com';

function formatDate(isoString) {
  const date = new Date(isoString); // Create Date object

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0'); // Add leading 0 if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so +1
  const year = date.getFullYear(); // Get full year

  // Return the formatted date
  return `${day}-${month}-${year}`; // Format dd-mm-yyyy
}

function Users() {
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState(usersData);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const [coins, setCoins] = useState("");
  const [activeTab, setActiveTab] = useState("wallet");
  const [activeGamesTab, setActiveGamesTab] = useState("A/B");
  const [ludoHistory , setLudoHistory] = useState([])
  const [abHistory , setAbHistory] = useState([])
  const [udHistory , setUdHistory] = useState([])
  const [coinHistory , setCoinHistory] = useState([])
  const [walletHistory, setWalletHistory] = useState([])

  const navigate = useNavigate()
  let token = localStorage.getItem('authToken');
  let Authorization = `Bearer ${token}`;

  const popupRef = useRef(null);

  useEffect(() => {
    fetch(`${baseUrl}/api/superAdmin/totalUsers`, {
      headers: {
        
        'Authorization': Authorization
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        setUsersData(data.users);
        setFilteredUsers(data.users);
      })
      .catch(error => {
        console.error('Error fetching user data:', error)
        // if()
        console.log(error)
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEntriesChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    const results = usersData.filter(
      (user) =>
        (user.phoneNumber && user.phoneNumber.toString().includes(searchTerm)) ||
      (user.userName && user.userName.toString().includes(searchTerm))
    );
    setFilteredUsers(results);
    setCurrentPage(1);
  }, [searchTerm, usersData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [entriesPerPage]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(order);
    setSortField(field);

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (typeof a[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      } else if (
        typeof a[field] === "number" ||
        field === "createdAt" ||
        field === "lastLogin"
      ) {
        return order === "asc"
          ? new Date(a[field]) - new Date(b[field])
          : new Date(b[field]) - new Date(a[field]);
      } else {
        return 0;
      }
    });

    setFilteredUsers(sortedUsers);
  };

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / entriesPerPage);
  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleAddCoinsClick = (user) => {
    setSelectedUser(user.phoneNumber);
    setIsPopupOpen(true);
  };

  const handleUserHistory = async (user) => {
    setIsDetailPopupOpen(true);
    console.log(user._id)
    try {
      const response = await fetch(`https://api.rapidxpro.com/api/wallet/getUsersHistory`, {
        method : "POST",
        headers: {
          'Authorization': Authorization,
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify({
          userId : user._id
        })
      });
      console.log(response)
  
      if (!response.ok) {
        throw new Error('Failed to fetch user history');
      }
  
      const result = await response.json(); // Parsing response body as JSON
      console.log(result);
      const coinHistory =   result.filter((item)=> item.description === "Invested" || item.description === "Auto Invest" || item.description === "Invested amount" || item.description === "Invested amount.")
      const userHistory =  result.filter((item)=> item.description !== "Invested" && item.description !== "Invested amount." && item.description !== "Invested amount" && item.description !== "Auto Invest" )
      console.log(coinHistory)
      console.log(userHistory)
      setWalletHistory(userHistory)
      setCoinHistory(coinHistory)

       // Fetching game history for each game
    const fetchGameHistory = async (gameSlug) => {
      const gameResponse = await fetch(`https://rapidgame.rapidxpro.com/rapidgameapi/api/v1/users/games/history`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "x-api-key" : "a467e5-d52d15-38533b-a5c5ca-5c1561"
        },
        body: JSON.stringify({
          GameSlug: gameSlug,
          ThirdPartyUserId: user._id, // Pass the user's ID here
          Page: 1,
          Limit: 10
        })
      });

      if (!gameResponse.ok) {
        throw new Error(`Failed to fetch game history for ${gameSlug}`);
      }

      const gameData = await gameResponse.json();
      console.log(gameData); 
      return gameData.Data.Content.GameHistory
      // Assuming the game history is in `history` field
    };

    // Fetch histories for each game
    const ludoHistory = await fetchGameHistory('ludo');
    const abHistory = await fetchGameHistory('andar_bahar');
    const udHistory = await fetchGameHistory('7_up_7_down');

    // Set the history for each game tab
    setLudoHistory(ludoHistory);
    setAbHistory(abHistory);
    setUdHistory(udHistory);
    } catch (error) {
      console.error('Error fetching user history:', error);
    }
  };
  

//   const getLudoHistory = async(page)=>{
//     try{
//         const headers = {
//             "Content-Type" :  "application/json" ,
//              "x-api-key" : "a467e5-d52d15-38533b-a5c5ca-5c1561"
//         }
//         const data ={
//             "GameSlug": "ludo",
//             "ThirdPartyUserId": user ? user.user._id : 0,
//              "Page": page,
//              "Limit": 10
//        }
//     //    console.log(data)
//         const result = await apiObj.getGameHistory(data , headers)
//         if(result.data.Data.StatusCode == 200){
//             if(result.data.Data.Content.GameHistory.length >= 1){
//                 setLudoHistory(result.data.Data.Content.GameHistory)
//             }else{
//                 setLudoHistory('empty')
//             }
//         }
//         // console.log(result)
//     }catch(err){
//         console.log(err)
//     }
// }
// const getABHistory = async(page)=>{
//     try{
//         const headers = {
//             "Content-Type" :  "application/json" ,
//              "x-api-key" : "a467e5-d52d15-38533b-a5c5ca-5c1561"
//         }
//         const data ={
//             "GameSlug": "andar_bahar",
//             "ThirdPartyUserId": user ? user.user._id : 0,
//              "Page": page,
//              "Limit": 10
//        }
//     //    console.log(data)
//         const result = await apiObj.getGameHistory(data , headers)
//         if(result.data.Data.StatusCode == 200){
//             if(result.data.Data.Content.GameHistory.length >= 1){
//                 setAbHistory(result.data.Data.Content.GameHistory)
//             }else{
//                 setAbHistory('empty')
//             }
//         }
//         // console.log(result)
//     }catch(err){
//         console.log(err)
//     }
// }
// const getUDHistory = async(page)=>{
//     try{
//         const headers = {
//             "Content-Type" :  "application/json" ,
//              "x-api-key" : "a467e5-d52d15-38533b-a5c5ca-5c1561"
//         }
//         const data ={
//             "GameSlug": "7_up_7_down",
//             "ThirdPartyUserId": user ? user.user._id : 0,
//              "Page": page,
//              "Limit": 10
//        }
//     //    console.log(data)
//         const result = await apiObj.getGameHistory(data , headers)
//         if(result.data.Data.StatusCode == 200){
//             if(result.data.Data.Content.GameHistory.length >= 1){
//                 setUdHistory(result.data.Data.Content.GameHistory)
//             }else{
//                 setUdHistory('empty')
//             }
//         }
//         // console.log(result)
//     }catch(err){
//         console.log(err)
//     }
// }

  const handleClosePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setIsPopupOpen(false);
      setIsDetailPopupOpen(false);
    }
  };

  const handleAddCoins = (e) => {
    e.preventDefault();
    fetch(`${baseUrl}/api/superAdmin/addCoins`, {
      method: 'POST',
      headers: {
        'Authorization': Authorization,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phoneNumber: selectedUser,
        coins: parseInt(coins, 10)
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if(data.error === "Request is not authorized"){
          localStorage.removeItem('authToken')
          navigate('/')
        }
        if(data.message === "Wallet is locked"){
          alert("User is in app , wallet is locked!")
          setIsPopupOpen(false);
          return;
        }
        alert('Coins added successfully:', data);
        setIsPopupOpen(false);
      })
      .catch(error => console.error('Error adding coins:', error));
  };

  const handleSubtractCoins = (e) => {
    e.preventDefault();
    fetch(`${baseUrl}/api/superAdmin/minusCoins`, {
        method: 'POST',
        headers: {
            'Authorization': Authorization,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            phoneNumber: selectedUser,
            coins: parseInt(coins, 10)
        })
    })
    .then(response => response.json())
    .then(data => {
      if(data.error === "Request is not authorized"){
        localStorage.removeItem('authToken')
        navigate('/')
      }
      if(data.error  ===  "User don't have enough balance"){
        alert("User don't have enough balance")
        setIsPopupOpen(false);
        return;
      }
      if(data.message === "Wallet is locked"){
        alert("User is in app , wallet is locked!")
        setIsPopupOpen(false);
        return;
      }
        alert('Coins subtracted successfully:', data);
        setIsPopupOpen(false);
    })
    .catch(error => console.error('Error subtracting coins:', error));
};

  useEffect(() => {
    document.addEventListener("mousedown", handleClosePopup);
    return () => {
      document.removeEventListener("mousedown", handleClosePopup);
    };
  }, []);



  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="bg-white rounded shadow p-4 max-w-full">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold mb-4">Users</h2>
        <button
          onClick={() => handleAddCoinsClick({ phoneNumber: '' })}
          className="bg-[#3c8dbc] text-white px-4 py-2 rounded border-[#367fa9]"
        >
          Add Coins
        </button>
      </div>
      <div className="flex justify-between">
        <div>
          <label>
            Show
            <select
              value={entriesPerPage}
              onChange={handleEntriesChange}
              className="ml-2 border"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{" "}
            entries
          </label>
        </div>
        <div>
          <label>
            Search:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              className="ml-2 border"
            />
          </label>
        </div>
      </div>
      <div className="w-full overflow-x-scroll ">
      
      <table className="min-w-full divide-y divide-gray-200 ">
        <thead>
          <tr>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Created At
              <button onClick={() => handleSort("createdAt")} className="ml-2">
                {sortOrder === "asc" && sortField === "createdAt" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Login Type
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Name
              <button onClick={() => handleSort("name")} className="ml-2">
                {sortOrder === "asc" && sortField === "name" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Email
              <button onClick={() => handleSort("email")} className="ml-2">
                {sortOrder === "asc" && sortField === "email" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              UserName
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Contact
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Wallet
              <button onClick={() => handleSort("coins")} className="ml-2">
                {sortOrder === "asc" && sortField === "coins" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Coins
              <button onClick={() => handleSort("coins")} className="ml-2">
                {sortOrder === "asc" && sortField === "coins" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              ReferredBy
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              App Version
              <button onClick={() => handleSort("appVersion")} className="ml-2">
                {sortOrder === "asc" && sortField === "appVersion" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Last Login At
              <button onClick={() => handleSort("lastLogin")} className="ml-2">
                {sortOrder === "asc" && sortField === "lastLogin" ? "▲" : "▼"}
              </button>
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Account Status
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-sm text-black font-bold tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
       
        <tbody >
          {displayedUsers.map((user, index) => (
            <tr key={index} onClick={() => handleUserHistory(user)}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(user.createdAt)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                Mobile OTP
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.userName || ""}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.phoneNumber || ""}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {user.coins.toFixed(3)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {user.totalCoinsTillToday.toFixed(2)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {user.referredBy}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {user.appVersion}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {formatDate(user.lastLoginAt)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="px-2 py-1 text-white bg-green-500 rounded-md w-[60px] text-center">
                  {user.accountStatus}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="flex gap-1 items-center justify-center">
                  <div className="h-[12px] w-[12px] rounded-full bg-[#991b1b]"></div>
                  <div>offline</div>
                </div>
              </td>
              <td className=" py-4 whitespace-nowrap text-sm text-gray-500  flex items-center space-x-1">
                <button
                 
                 onClick={(e) => {
                  e.stopPropagation(); // Prevents row click
                  handleAddCoinsClick(user);
                }}
                >
                  <div className="bg-[#3c8dbc] px-1 py-0 rounded">
                    <i className="fa-solid fa-money-bill text-white"></i>
                  </div>
                </button>
                

                <button className="text-red-600 hover:text-red-900 ml-4">
                  <div className="bg-[#3c8dbc] px-1 py-0 rounded">
                    <i className="fa-solid fa-lock text-white"></i>
                  </div>
                </button>
                
                <button className="text-red-600 hover:text-red-900 ml-4">
                <Link to={`/UserProfile/${user._id}`}>
                  <div className="bg-[#3c8dbc] px-1 py-0 rounded">
                    <i className="fa-solid fa-user text-white"></i>
                  </div>
                  </Link>
                </button>
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="mt-4 flex justify-between">
        <span>
          Showing {(currentPage - 1) * entriesPerPage + 1} to{" "}
          {Math.min(currentPage * entriesPerPage, totalItems)} of {totalItems}{" "}
          entries
        </span>
        <div className="flex space-x-2">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={handlePreviousPage}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={handleNextPage}
          >
            Next
          </button>
        </div>
      </div>
      {
      isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div
            ref={popupRef}
            className="bg-white p-4 rounded-lg shadow-md w-[400px]"
          >
            <h3 className="text-lg font-semibold mb-4">Add Coins</h3>

            <form>
              <div className="mb-4">
                <label htmlFor="userSelect" className="block mb-2">
                  Users
                </label>
                <select
                  id="userSelect"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                  className="w-full border px-2 py-1"
                >
                  <option value="" disabled>
                    Select a user
                  </option>
                  {usersData.slice().reverse().map((user, index) => (
                    <option key={index} value={user.phoneNumber}>
                      {user.phoneNumber}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="coins" className="block mb-2">
                  Coins
                </label>
                <input
                  type="number"
                  id="coins"
                  value={coins}
                  onChange={(e) => setCoins(e.target.value)}
                  className="w-full border px-2 py-1"
                  placeholder="Coins"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button onClick={handleAddCoins} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
                  Add
                </button>
                <button onClick={handleSubtractCoins} className="bg-blue-500 text-white px-4 py-2 rounded">
                  Minus
                </button>
              </div>
            </form>
          </div>
        </div>
      )}


{isDetailPopupOpen && (
        <div className="fixed max-h-[100vh] overflow-auto inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div ref={popupRef} className="bg-white max-h-[400px] overflow-auto p-4 rounded-lg shadow-md w-[600px]">
          <h3 className="text-lg font-semibold mb-4">User History</h3>

          {/* Main Tabs */}
          <div className="flex justify-center mb-4">
            <button
              onClick={() => setActiveTab("wallet")}
              className={`px-4 py-2 mx-2 ${activeTab === "wallet" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
            >
              Wallet
            </button>
            <button
              onClick={() => setActiveTab("rapidCoins")}
              className={`px-4 py-2 mx-2 ${activeTab === "rapidCoins" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
            >
              Rapid Coins
            </button>
            <button
              onClick={() => setActiveTab("games")}
              className={`px-4 py-2 mx-2 ${activeTab === "games" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
            >
              Games
            </button>
          </div>

          {/* Sub-Tabs for Games */}
          {activeTab === "games" && (
            <div className="flex justify-center mb-4">
              <button
                onClick={() => setActiveGamesTab("A/B")}
                className={`px-4 py-2 mx-2 ${activeGamesTab === "A/B" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
              >
                A/B
              </button>
              <button
                onClick={() => setActiveGamesTab("7UD")}
                className={`px-4 py-2 m mx-2 ${activeGamesTab === "7UD" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
              >
                7UD
              </button>
              <button
                onClick={() => setActiveGamesTab("Ludo")}
                className={`px-4 py-2 m mx-2 ${activeGamesTab === "Ludo" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}
              >
                Ludo
              </button>
            </div>
          )}

          {/* Table for History */}
          
<table className="min-w-full bg-white">
  <thead>
    <tr>
      {activeTab === "games" ? (
        <>
          <th className="px-4 py-2 border">Date</th>
          <th className="px-4 py-2 border">Game</th>
          <th className="px-4 py-2 border">Amount</th>
          <th className="px-4 py-2 border">Win Amount</th>
          <th className="px-4 py-2 border">Status</th>
        </>
      ) : (
        <>
          <th className="px-4 py-2 border">Date</th>
          <th className="px-4 py-2 border">Description</th>
          <th className="px-4 py-2 border">Amount</th>
          <th className="px-4 py-2 border">Closing Balance</th>
        </>
      )}
    </tr>
  </thead>
  <tbody>
    {activeTab === "wallet" &&
      walletHistory.map((entry, index) => (
        <tr key={index}>
          <td className="px-4 py-2 border">{formatDate(entry.date)}</td>
          <td className="px-4 py-2 border">{entry.description}</td>
          <td className="px-4 py-2 border">{entry.amount}</td>
          <td className="px-4 py-2 border">{entry.balanceAfterTransaction}</td>
        </tr>
      ))}

    {activeTab === "rapidCoins" &&
      coinHistory.map((entry, index) => (
        <tr key={index}>
          <td className="px-4 py-2 border">{formatDate(entry.date)}</td>
          <td className="px-4 py-2 border">{entry.description}</td>
          <td className="px-4 py-2 border">{entry.amount}</td>
          <td className="px-4 py-2 border">{entry.balanceAfterTransaction}</td>
        </tr>
      ))}

    {activeTab === "games" && activeGamesTab === "A/B" &&
      abHistory && abHistory.map((entry, index) => (
        <tr key={index}>
          <td className="px-4 py-2 border">{formatDate(entry.CreatedAt)}</td>
          <td className="px-4 py-2 border">Aandar-Bahar</td>
          <td className="px-4 py-2 border">{entry.BetAmount}</td>
          <td className="px-4 py-2 border">{entry.WinAmount}</td>
          <td className="px-4 py-2 border">{entry.IsWin == 1 ? 'Won' : 'Loss' }</td>
        </tr>
      ))}

    {activeTab === "games" && activeGamesTab === "7UD" &&
      udHistory && udHistory.map((entry, index) => (
        <tr key={index}>
          <td className="px-4 py-2 border">{formatDate(entry.CreatedAt)}</td>
          <td className="px-4 py-2 border">7-up-7-down</td>
          <td className="px-4 py-2 border">{entry.BetAmount}</td>
          <td className="px-4 py-2 border">{entry.WinAmount}</td>
          <td className="px-4 py-2 border">{entry.IsWin == 1 ? 'Won' : 'Loss' }</td>
        </tr>
      ))}

    {activeTab === "games" && activeGamesTab === "Ludo" &&
      ludoHistory && ludoHistory.map((entry, index) => (
        <tr key={index}>
          <td className="px-4 py-2 border">{formatDate(entry.CreatedAt)}</td>
          <td className="px-4 py-2 border">Ludo</td>
          <td className="px-4 py-2 border">{entry.BetAmount}</td>
          <td className="px-4 py-2 border">{entry.WinAmount}</td>
          <td className="px-4 py-2 border">{entry.IsWin == 1 ? 'Won' : 'Loss' }</td>
        </tr>
      ))}
  </tbody>
</table>


     

        <div className="flex justify-end mt-3" onClick={(event)=>event.stopPropagation()}>
          <button
            onClick={() => setIsDetailPopupOpen(false)}
            className="bg-yellow-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
        
        </div>
    
    </div>
  </div>
)}

    </div>
    
  );
}

export default Users;
