
import React, { useState,useEffect } from 'react';

const LotteryComponent = () => {
  // State to handle which option is selected
  const [selection, setSelection] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [winNumber, setWinNumber] = useState('');  // Lottery number for manual mode
  const [loading, setLoading] = useState(false);   // To handle the loading state


    // Retrieve data from localStorage on component mount
    useEffect(() => {
      const savedSelection = localStorage.getItem('selection');
      const savedDifficulty = localStorage.getItem('difficulty');
      const savedWinNumber = localStorage.getItem('winNumber');
      
      if (savedSelection) setSelection(savedSelection);
      if (savedDifficulty) setDifficulty(savedDifficulty);
      if (savedWinNumber) setWinNumber(savedWinNumber);
    }, []);
   // Handle radio selection change (manual/automatic)
   const handleSelectionChange = (e) => {
    const value = e.target.value;
    setSelection(value);
    setDifficulty(''); // Reset difficulty when switching between Manual/Automatic
    localStorage.setItem('selection', value); // Save to localStorage
  };

  // Handle difficulty change
  const handleDifficultyChange = (e) => {
    const value = e.target.value;
    setDifficulty(value);
    localStorage.setItem('difficulty', value); // Save to localStorage
  };

  // Handle win number input change
  const handleWinNumberChange = (e) => {
    const value = e.target.value;
    setWinNumber(value);
    localStorage.setItem('winNumber', value); // Save to localStorage
  };

  const handleSubmit = async () => {
    setLoading(true);

    const apiUrl = 'https://rapidgame.rapidxpro.com/rapidgameapi/api/v1/lottery/daily/gameresult/settings/update';
    const apiKey = 'a467e5-d52d15-38533b-a5c5ca-5c1561';

    let data = {};

    if (selection === 'manual') {
      // Manual mode selected
      data = {
        Data: {
          GameResultMode: 'manual',
          GameResultDifficultyLevel: 'easy',
          WinNumber: winNumber
        }
      };
    } else if (selection === 'automatic') {
      // Automatic mode selected
      data = {
        Data: {
          GameResultMode: 'automatic',
          GameResultDifficultyLevel: difficulty
        }
      };
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify(data)
      });
      console.log(data)
      const result = await response.json();
      console.log('API Response:', result);
      // You can add your success/failure handling here based on the result
      alert('Settings updated successfully!');

    } catch (error) {
      console.error('Error in API call:', error);
      alert('Failed to update settings');
    }

    setLoading(false);
  };


  

  return (
    <div>
      <h3 className='text-[28px] font-bold'>Select  Option for lottery Number</h3>

      {/* Manual/Automatic radio buttons */}
      <div className='flex items-center justify-around mt-10'>
        <label className='text-[25px]'>
          <input
            type="radio"
            value="manual"
            checked={selection === 'manual'}
            onChange={handleSelectionChange}
            className="mr-2 w-5 h-5" // Increase width and height
          />
          Manual
        </label>
        <label className='text-[25px]'>
          <input
            type="radio"
            value="automatic"
            checked={selection === 'automatic'}
            onChange={handleSelectionChange}
            className="mr-2 w-5 h-5" // Increase width and height
          />
          Automatic
        </label>
      </div>

      {/* Manual input field */}
      {selection === 'manual' && (
            <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">

            <form>
              <div className="mb-4">
                <label htmlFor="winningNumber" className="block text-gray-700 mb-3">
                  Lottery Number
                </label>
                <input
                  type="number"
                  id="winningNumber"
                  value={winNumber}
                  onChange={handleWinNumberChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                  required
                />
              </div>
      
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={loading}  // Disable button while loading
                className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-blue-600"
              >
                  {loading ? 'Saving...' : 'Save'}
              </button>
            </form>
          </div>
      )}

      {/* Automatic difficulty levels */}
      {selection === 'automatic' && (
          <div className="max-w-md mx-auto mt-10 p-6 shadow-md rounded-md">
          <h4 className='text-[22px] mb-4'>Select Difficulty :</h4>
          <div className='flex items-center justify-around'>
          <label className='text-[18px]'>
            <input
              type="radio"
              value="easy"
              checked={difficulty === 'easy'}
              onChange={handleDifficultyChange}
              className="mr-2 w-4 h-4" // Increase width and height
            />
            Easy
          </label>
          <label  className='text-[18px]'>
            <input
              type="radio"
              value="medium"
              checked={difficulty === 'medium'}
              onChange={handleDifficultyChange}
              className="mr-2 w-4 h-4" // Increase width and height
            />
            Medium
          </label>
          <label className='text-[18px]'>
            <input
              type="radio"
              value="hard"
              checked={difficulty === 'hard'}
              onChange={handleDifficultyChange}
              className="mr-2 w-4 h-4" // Increase width and height
            />
            Hard
          </label>
          </div>

          <button
            type="submit"
            onClick={handleSubmit}
            disabled={loading}  // Disable button while loading
            className="w-full mt-6 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:bg-blue-600"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      )}
    </div>
  );
};

export default LotteryComponent;

